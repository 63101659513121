import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`3rd August 2020`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You can now add multiple request timeout callbacks using the following methods: `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2937"
          }}>{`#2937`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2939"
          }}>{`#2939`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://ClientRequestContext#whenResponseTimingOut():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientRequestContext.html#whenResponseTimingOut()"
            }}>{`type://ClientRequestContext#whenResponseTimingOut()`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://ClientRequestContext#whenResponseTimedOut():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientRequestContext.html#whenResponseTimedOut()"
            }}>{`type://ClientRequestContext#whenResponseTimedOut()`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://ServiceRequestContext#whenRequestTimingOut():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html#whenRequestTimingOut()"
            }}>{`type://ServiceRequestContext#whenRequestTimingOut()`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://ServiceRequestContext#whenRequestTimedOut():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html#whenRequestTimedOut()"
            }}>{`type://ServiceRequestContext#whenRequestTimedOut()`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify multiple decorators with a single call when building a service binding fluently. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2889"
          }}>{`#2889`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server.builder()
      .route().get("/hello")
              .decorators(firstDecorator,
                          secondDecorator,
                          thirdDecorator)
              .build(helloService)
      .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify whether the entire annotated service methods have to run in a `}<inlineCode parentName="p">{`blockingTaskExecutor`}</inlineCode>{`.
`}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2923"
          }}>{`#2923`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2925"
          }}>{`#2925`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server.builder()
      .annotatedService().pathPrefix("/my_service")
                         .useBlockingTaskExecutor(true)
                         .build(myService);

`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can also specify the `}<a parentName="p" {...{
            "href": "type://@Blocking:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Blocking.html"
          }}>{`type://@Blocking`}</a>{` annotation on a class. It was previously allowed only on methods.
`}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2915"
          }}>{`#2915`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`@Blocking
public class MyBlockingAnnotatedService {
    @Get
    public String delayed() throws Exception {
        Thread.sleep(1000);
        return "slept a second";
    }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now bind an annotated service method to the prefix path by specifying no path. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2708"
          }}>{`#2708`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2853"
          }}>{`#2853`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`public class MyAnnotatedService {
    @Get
    public String withoutSlash() {
        return "without slash";
    }

    @Get("/")
    public String withSlash() {
        return "with slash";
    }
}

Server server =
    Server.builder()
          .http(8080)
          .annotatedService("/prefix", new MyAnnotatedService())
          .build();
server.start().join();

assert "without slash".equals(
    WebClient.of().get("http://127.0.0.1:8080/prefix")
             .aggregate().toStringUtf8());

assert "with slash".equals(
    WebClient.of().get("http://127.0.0.1:8080/prefix/")
             .aggregate().toStringUtf8());
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now send and receive `}<inlineCode parentName="p">{`grpc-web-text`}</inlineCode>{` messages in gRPC, as well as `}<inlineCode parentName="p">{`grpc-web+proto`}</inlineCode>{` and
`}<inlineCode parentName="p">{`grpc-web+json`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2938"
          }}>{`#2938`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2955"
          }}>{`#2955`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`MyGrpcServiceStub client =
    Clients.newClient("gproto-web-text+h2c://example.com",
                      MyGrpcServiceStub.class);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now send a request via an HAPROXY server preserving the original source and destination addresses.
`}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2589"
          }}>{`#2589`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2907"
          }}>{`#2907`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ClientFactory factory =
    ClientFactory.builder()
                 .proxyConfig(ProxyConfig.haproxy(haproxyServerAddr))
                 .build();

WebClient client =
    WebClient.builder()
             .factory(factory)
             .build();

// Send an HTTP request to example.com via an HAPROXY server,
// preserving the source address by using HAPROXY protocol.
client.get("http://example.com/").aggregate().join();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use different `}<a parentName="p" {...{
            "href": "type://ProxyConfig:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/proxy/ProxyConfig.html"
          }}>{`type://ProxyConfig`}</a>{` for different `}<a parentName="p" {...{
            "href": "typeplural://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
          }}>{`typeplural://Endpoint`}</a>{` using
`}<a parentName="p" {...{
            "href": "type://ProxyConfigSelector:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/proxy/ProxyConfigSelector.html"
          }}>{`type://ProxyConfigSelector`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2752"
          }}>{`#2752`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ProxyConfigSelector mySelector = (protocol, endpoint) -> {
    // Send the requests to *.foo.com via a SOCKS5 server.
    if (endpoint.host().endsWith(".foo.com")) {
        return ProxyConfig.socks5(socksServerAddr);
    }
    // Send the requests to *.bar.com via a HAPROXY server.
    if (endpoint.host().endsWith(".bar.com")) {
        return ProxyConfig.haproxy(haproxyServerAddr);
    }
    return ProxyConfig.direct();
};

ClientFactory factory =
    ClientFactory.builder()
                 .proxyConfig(mySelector)
                 .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added `}<a parentName="p" {...{
            "href": "type://Route#patternString():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Route.html#patternString()"
          }}>{`type://Route#patternString()`}</a>{` which can be used as a simple human-readable description of
a `}<a parentName="p" {...{
            "href": "type://Route:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Route.html"
          }}>{`type://Route`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2932"
          }}>{`#2932`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2933"
          }}>{`#2933`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "type://HttpData:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpData.html"
          }}>{`type://HttpData`}</a>{` now extends `}<inlineCode parentName="p">{`AutoCloseable`}</inlineCode>{` and provides various methods useful when using
`}<a parentName="p" {...{
            "href": "type://SubscriptionOption#WITH_POOLED_OBJECTS:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/SubscriptionOption.html#WITH_POOLED_OBJECTS"
          }}>{`type://SubscriptionOption#WITH_POOLED_OBJECTS`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2892"
          }}>{`#2892`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Note that this feature is only for the advanced users who want to reduce the number of memory copies
in a performance-sensitive environment.`}</li>
          <li parentName="ul">{`See `}<a parentName="li" {...{
              "href": "type://PooledObjects:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/unsafe/PooledObjects.html"
            }}>{`type://PooledObjects`}</a>{` for more information.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul">{`We now use the following `}<a parentName="li" {...{
          "href": "https://errorprone.info/"
        }}>{`Google Error Prone`}</a>{` annotations which might be useful
if you use Error Prone already or your static analysis tool supports them: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2876"
        }}>{`#2876`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2908"
        }}>{`#2908`}</a>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`@CheckReturnValue`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`@FormatMethod`}</inlineCode>{` and `}<inlineCode parentName="li">{`@FormatString`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`@MustBeClosed`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul">{`You can now specify the connection ping interval less than 10 seconds, which was previously impossible. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2944"
        }}>{`#2944`}</a></li>
      <li parentName="ul">{`Improved the load distribution of client requests by randomizing how we choose the initial event loop,
which may reduce the error rate in a certain situation. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2941"
        }}>{`#2941`}</a></li>
      <li parentName="ul">{`Improved the performance of `}<a parentName="li" {...{
          "href": "type://Endpoint#toString():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html#toString()"
        }}>{`type://Endpoint#toString()`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2906"
        }}>{`#2906`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ClientRequestContext#currentOrNull():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientRequestContext.html#currentOrNull()"
        }}>{`type://ClientRequestContext#currentOrNull()`}</a>{` and `}<a parentName="li" {...{
          "href": "type://ServiceRequestContext#currentOrNull():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html#currentOrNull()"
        }}>{`type://ServiceRequestContext#currentOrNull()`}</a>{` don't throw
an `}<inlineCode parentName="li">{`IllegalStateException`}</inlineCode>{` anymore but return `}<inlineCode parentName="li">{`null`}</inlineCode>{` when the current context type mismatches. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2962"
        }}>{`#2962`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://Endpoint#parse(String):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html#parse(java.lang.String)"
        }}>{`type://Endpoint#parse(String)`}</a>{` doesn't fail anymore when given with an authority with a userInfo part.
`}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2869"
        }}>{`#2869`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2893"
        }}>{`#2893`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`req.serviceName`}</inlineCode>{` in `}<a parentName="li" {...{
          "href": "type://BuiltInProperties"
        }}>{`type://BuiltInProperties`}</a>{` has been renamed to `}<inlineCode parentName="li">{`req.service_name`}</inlineCode>{` for the consistency
with other property names. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2958"
        }}>{`#2958`}</a></li>
      <li parentName="ul">{`We now handle an HTTP/2 request with a `}<inlineCode parentName="li">{`Host`}</inlineCode>{` header properly, matching the behavior of HAPROXY. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2877"
        }}>{`#2877`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2878"
        }}>{`#2878`}</a></li>
      <li parentName="ul">{`You can now send an HTTPS request via an HTTPS CONNECT proxy server (double encryption). `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2887"
        }}>{`#2887`}</a></li>
      <li parentName="ul">{`Fixed the buffer leaks in the following places: 😱 `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2891"
        }}>{`#2891`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2892"
        }}>{`#2892`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2951"
        }}>{`#2951`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
            }}>{`type://RetryingClient`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://CircuitBreakerClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerClient.html"
            }}>{`type://CircuitBreakerClient`}</a></li>
          <li parentName="ul"><inlineCode parentName="li">{`DefaultDnsNameResolver`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`DefaultStreamMessageDuplicator`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul">{`You are now disallowed to set a `}<inlineCode parentName="li">{`:status`}</inlineCode>{` header when building an `}<inlineCode parentName="li">{`HttpFile`}</inlineCode>{`, because it will make
`}<inlineCode parentName="li">{`FileService`}</inlineCode>{` misbehave. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2926"
        }}>{`#2926`}</a>{`.`}</li>
      <li parentName="ul">{`Fixed an `}<inlineCode parentName="li">{`IllegalReferenceCountException`}</inlineCode>{` when content preview is turned on. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2905"
        }}>{`#2905`}</a></li>
      <li parentName="ul">{`gRPC-Web trailers are now compressed and decompressed properly. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2930"
        }}>{`#2930`}</a></li>
      <li parentName="ul">{`Fixed the compatibility issues with `}<inlineCode parentName="li">{`grpc-kotlin`}</inlineCode>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2947"
        }}>{`#2947`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2950"
        }}>{`#2950`}</a></li>
      <li parentName="ul">{`You can now use the graceful shutdown properties when integration with Spring Boot 1. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2954"
        }}>{`#2954`}</a></li>
      <li parentName="ul">{`You can now use `}<a parentName="li" {...{
          "href": "type://DocServiceConfigurator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/spring/DocServiceConfigurator.html"
        }}>{`type://DocServiceConfigurator`}</a>{` when integrating with Spring Boot 2. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2960"
        }}>{`#2960`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://MetricCollectingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/metric/MetricCollectingService.html"
        }}>{`type://MetricCollectingService`}</a>{` now decorates all services if the `}<inlineCode parentName="li">{`armeria.enableMetrics`}</inlineCode>{` property is set
when integrating with Spring Boot. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2873"
        }}>{`#2873`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2898"
        }}>{`#2898`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-deprecations",
        "aria-label": "️ deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🏚️ Deprecations`}</h2>
    <ul>
      <li parentName="ul">{`All option constants in `}<a parentName="li" {...{
          "href": "type://ClientOption:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientOption.html"
        }}>{`type://ClientOption`}</a>{` and `}<a parentName="li" {...{
          "href": "type://ClientFactoryOption:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactoryOption.html"
        }}>{`type://ClientFactoryOption`}</a>{` have been moved to
`}<a parentName="li" {...{
          "href": "type://ClientOptions:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientOptions.html"
        }}>{`type://ClientOptions`}</a>{` and `}<a parentName="li" {...{
          "href": "type://ClientFactoryOptions:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactoryOptions.html"
        }}>{`type://ClientFactoryOptions`}</a>{` respectively. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2928"
        }}>{`#2928`}</a></li>
      <li parentName="ul">{`Various implementation classes have been deprecated in favor of static factory methods: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2875"
        }}>{`#2875`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2890"
        }}>{`#2890`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://MetricCollectingCircuitBreakerListener"
            }}>{`type://MetricCollectingCircuitBreakerListener`}</a>{` → `}<a parentName="li" {...{
              "href": "type://CircuitBreakerListener#metricCollecting(MeterRegistry):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerListener.html#metricCollecting(io.micrometer.core.instrument.MeterRegistry)"
            }}>{`type://CircuitBreakerListener#metricCollecting(MeterRegistry)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://RateLimitingThrottlingStrategy"
            }}>{`type://RateLimitingThrottlingStrategy`}</a>{` → `}<a parentName="li" {...{
              "href": "type://ThrottlingStrategy#rateLimiting(double):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/throttling/ThrottlingStrategy.html#rateLimiting(double)"
            }}>{`type://ThrottlingStrategy#rateLimiting(double)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://ConnectionPoolLoggingListener"
            }}>{`type://ConnectionPoolLoggingListener`}</a>{` → `}<a parentName="li" {...{
              "href": "type://ConnectionPoolListener#logging():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ConnectionPoolListener.html#logging()"
            }}>{`type://ConnectionPoolListener#logging()`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://StickyEndpointSelectionStrategy"
            }}>{`type://StickyEndpointSelectionStrategy`}</a>{` → `}<a parentName="li" {...{
              "href": "type://EndpointSelectionStrategy#sticky(ToLongFunction):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointSelectionStrategy.html#sticky(java.util.function.ToLongFunction)"
            }}>{`type://EndpointSelectionStrategy#sticky(ToLongFunction)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://TTextProtocol"
            }}>{`type://TTextProtocol`}</a>{` → `}<a parentName="li" {...{
              "href": "type://TTextProtocolFactory#getProtocol(TTransport):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/thrift/text/TTextProtocolFactory.html#getProtocol(org.apache.thrift.transport.TTransport)"
            }}>{`type://TTextProtocolFactory#getProtocol(TTransport)`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://Route#meterTag()"
        }}>{`type://Route#meterTag()`}</a>{` and `}<a parentName="li" {...{
          "href": "type://Route#loggerName()"
        }}>{`type://Route#loggerName()`}</a>{` have been deprecated in favor of
`}<a parentName="li" {...{
          "href": "type://Route#patternString():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Route.html#patternString()"
        }}>{`type://Route#patternString()`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2933"
        }}>{`#2933`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ClientRequestContext#responseTimeoutHandler()"
        }}>{`type://ClientRequestContext#responseTimeoutHandler()`}</a>{`,
`}<a parentName="li" {...{
          "href": "type://ClientRequestContext#setResponseTimeoutHandler(Runnable)"
        }}>{`type://ClientRequestContext#setResponseTimeoutHandler(Runnable)`}</a>{`,
`}<a parentName="li" {...{
          "href": "type://ServiceRequestContext#requestTimeoutHandler()"
        }}>{`type://ServiceRequestContext#requestTimeoutHandler()`}</a>{` and
`}<a parentName="li" {...{
          "href": "type://ServiceRequestContext#setRequestTimeoutHandler(Runnable)"
        }}>{`type://ServiceRequestContext#setRequestTimeoutHandler(Runnable)`}</a>{` have been deprecated in favor of
`}<a parentName="li" {...{
          "href": "type://ClientRequestContext#whenResponseTimedOut():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientRequestContext.html#whenResponseTimedOut()"
        }}>{`type://ClientRequestContext#whenResponseTimedOut()`}</a>{` and
`}<a parentName="li" {...{
          "href": "type://ServiceRequestContext#whenRequestTimedOut():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html#whenRequestTimedOut()"
        }}>{`type://ServiceRequestContext#whenRequestTimedOut()`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2939"
        }}>{`#2939`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul">{`Added `}<inlineCode parentName="li">{`final`}</inlineCode>{` to the various classes and methods so that a user doesn't extend them beyond the devs'
intention. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2927"
        }}>{`#2927`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`com.linecorp.armeria.common.util.NonNullByDefault`}</inlineCode>{` and `}<inlineCode parentName="li">{`UnstableApi`}</inlineCode>{` have been moved to
`}<inlineCode parentName="li">{`com.linecorp.armeria.common.annotation`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2890"
        }}>{`#2890`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`KeyedCircuitBreakerMapping`}</inlineCode>{` and its inner class are now package-private. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2890"
        }}>{`#2890`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Use the static factory methods in `}<a parentName="li" {...{
              "href": "type://CircuitBreakerMapping:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerMapping.html"
            }}>{`type://CircuitBreakerMapping`}</a>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`A `}<inlineCode parentName="li">{`Host`}</inlineCode>{` header isn't automatically translated into the `}<inlineCode parentName="li">{`:authority`}</inlineCode>{` header anymore, so that you can preserve
the original headers when writing a proxy server with Armeria. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2877"
        }}>{`#2877`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2878"
        }}>{`#2878`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`req.serviceName`}</inlineCode>{` in `}<a parentName="li" {...{
          "href": "type://BuiltInProperties"
        }}>{`type://BuiltInProperties`}</a>{` has been renamed to `}<inlineCode parentName="li">{`req.service_name`}</inlineCode>{` for the consistency
with other property names. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2958"
        }}>{`#2958`}</a></li>
      <li parentName="ul">{`Various classes and methods that were previously deprecated have been removed. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2926"
        }}>{`#2926`}</a></li>
      <li parentName="ul">{`The new `}<inlineCode parentName="li">{`Pooled*`}</inlineCode>{` API introduced in the previous release has been reverted due to design and performance
issues. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2882"
        }}>{`#2882`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2892"
        }}>{`#2892`}</a>
        <ul parentName="li">
          <li parentName="ul">{`See `}<a parentName="li" {...{
              "href": "type://PooledObjects:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/unsafe/PooledObjects.html"
            }}>{`type://PooledObjects`}</a>{` for the new API.`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`ClientFactoryOption.PROXY_CONFIG`}</inlineCode>{` has been replaced with `}<a parentName="li" {...{
          "href": "type://ClientFactoryOptions#PROXY_CONFIG_SELECTOR:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactoryOptions.html#PROXY_CONFIG_SELECTOR"
        }}>{`type://ClientFactoryOptions#PROXY_CONFIG_SELECTOR`}</a>{`.
`}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2752"
        }}>{`#2752`}</a></li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Bouncy Castle 1.65.01 → 1.66`}</li>
      <li parentName="ul">{`Brave 5.12.3 → 5.12.4`}</li>
      <li parentName="ul">{`Dropwizard 2.0.10 → 2.0.12, 1.3.23 → 1.3.24`}</li>
      <li parentName="ul">{`Dropwizard Metrics 4.1.9 → 4.1.11`}</li>
      <li parentName="ul">{`gRPC 1.30.2 → 1.31.0`}</li>
      <li parentName="ul">{`Jackson 2.11.1 → 2.11.2`}</li>
      <li parentName="ul">{`Jetty 9.4.30 → 9.4.31`}</li>
      <li parentName="ul">{`Micrometer 1.5.2 → 1.5.3, 1.3.10 → 1.3.11`}</li>
      <li parentName="ul">{`Netty 4.1.50 → 4.1.51`}
        <ul parentName="li">
          <li parentName="ul">{`netty-tcnative-boringssl-static 2.0.30 → 2.0.31`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Reactor 3.3.7 → 3.3.8`}</li>
      <li parentName="ul">{`RxJava 3.0.4 → 3.0.5`}</li>
      <li parentName="ul">{`Spring Boot 2.3.1 → 2.3.2`}</li>
      <li parentName="ul">{`Tomcat 9.0.36 → 9.0.37, 8.5.56 → 8.5.57`}</li>
      <li parentName="ul">{`Shaded dependencies:`}
        <ul parentName="li">
          <li parentName="ul">{`completable-futures 0.3.2 → 0.3.3`}</li>
          <li parentName="ul">{`fastutil 8.3.1 → 8.4.0`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['anuraaga', 'codefromthecrypt', 'ghkim3221', 'heowc', 'ikhoon', 'imasahiro', 'jrhee17', 'KarboniteKream', 'kojilin', 'm50d', 'mauhiz', 'matsumana', 'minwoox', 'okue', 'smax48', 'tobias-', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      